import React, { useEffect, useState } from 'react'
import HTMLFlipBook from 'react-pageflip';
import { Document, Page, pdfjs } from 'react-pdf'
import { useSearchParams } from 'react-router-dom'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

export default function ReadPDF() {

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

    const BASEURL = 'https://algoloomstudio.com/apiebookitn/api/'
    const [params, setParams] = useSearchParams()
    const [fileName, setfileName] = useState('')
    const [pagesCount, setPagesCount] = useState('')
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)


    useEffect(() => {
        getBook()
    }, [])


    async function getBook() {
        try {
            console.log('ngehe');
            const id = params.get('id')

            try {
                const data = await fetch(BASEURL + 'biblioDetail/' + id,{
                    method: "GET",
                })
                const rs = await data.json()
                setfileName(BASEURL + 'uploads/' + rs.data?.file + '')
            } catch (e) {
                console.log('error su');
            }

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <Document file={fileName}
            onSourceError={(e) => {
                console.log('src err ' + e.message);
            }}
            onLoadError={(e) => {
                console.log('error e' + e.message);
            }}
            onLoadSuccess={(docs) => {
                console.log('success');
                setPagesCount(docs.numPages)
            }}>

            <HTMLFlipBook
                width={width} // base page width
                height={height} // base page height
                size={"stretch"}
                minWidth={315}
                maxWidth={width-50}
                minHeight={420}
                maxHeight={height}
                maxShadowOpacity={0.5} // Half shadow intensity
                mobileScrollSupport={false} // disable content scrolling on mobile devices
            >
                {Array.from({ length: pagesCount }, (item, idx) => (
                    <div className='demoPage' key={idx}>
                        <Page pageNumber={idx + 1} scale={0.6}
                            renderTextLayer={false} />
                    </div>
                ))}
            </HTMLFlipBook>
        </Document>

    );
}
